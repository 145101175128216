import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Concession } from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectGiftCardRecipient,
  selectIsCustomerReadyForPayment,
  selectSelectedGiftCards,
  selectShouldShowGuestMarketingOptions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import PaymentSummaryRowEGiftCard from '../../common/concessionsonlycommon/egiftcard/PaymentSummaryRowEGiftCard';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import GuestMarketing from '../../common/payment/others/GuestMarketing';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';

const GiftCardPayment: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const shouldShowGuestMarketingOptions = useSelector(
    selectShouldShowGuestMarketingOptions
  );
  const showNewsLetterSignUp =
    config?.payment.showGuestNewsletterSignUpCheckbox &&
    !bookingData.isUserValidated;
  const canLaunchPayment = isCustomerReadyForPayment && !showNewsLetterSignUp;
  const [isPageValidated, setIsPageValidated] = useState(!!canLaunchPayment);
  const [showPaymentForm, setShowPaymentForm] = useState(true);

  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const [creditCardType, setCreditCardType] = useState('');
  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  useAnalyticsTrackOnce(TrackingEvent.GIFTCARD_PAYMENT_LANDING);

  useConfirmationPageRedirect();

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setShowPaymentForm(nextShowPaymentForm);
  };

  const handleValidatePage = () => {
    setIsPageValidated(true);
  };

  const resetHostedPayment = () => {
    setIsPageValidated(false);
    dispatch(actionCreators.removeHostedPayments());
  };

  const getGiftCardRows = () => {
    return selectedGiftCards.list.map((c: Concession, i: number) => (
      <PaymentSummaryRowEGiftCard
        selectedGiftCard={c}
        key={`giftcardrow_${i}`}
      />
    ));
  };

  if (!content || !config || !bookingData) return null;

  return (
    <div className='payment text-center' data-testid='payment'>
      <div className='giftcards'>
        <Row className='text-center'>
          <Col xs={12}>
            <Heading size={1}>{content.giftCards.mainTitle}</Heading>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className='contained'>
            <Heading size={2} className='mt-3 text-center'>
              {content.giftCards.paymentHeading}
            </Heading>
            <RichText text={content.giftCards.paymentRichText} />
          </Col>
        </Row>

        <RecaptchaText />

        <Row className='px-2 px-md-0'>
          <Col md={10} className='giftcard-rows-container summary-rows mt-4'>
            {getGiftCardRows()}
            <Row className='recipient-details summary text-start tiny pt-3 pb-4'>
              <Col xs={12} className='contained'>
                <div className='detail'>
                  <div>
                    <span className='fw-bold'>
                      {content.giftCards.paymentRecipientNameLabel}
                    </span>{' '}
                    <span>{giftCardRecipient.name}</span>
                  </div>
                  <div>
                    <span className='fw-bold'>
                      {content.giftCards.paymentRecipientEmailLabel}
                    </span>{' '}
                    <span>{giftCardRecipient.email}</span>
                  </div>

                  <div className='mt-1'>
                    <span className='fw-bold'>
                      {content.giftCards.paymentBillingNameLabel}
                    </span>{' '}
                    <span>{customer.name}</span>
                  </div>

                  <div>
                    <span className='fw-bold'>
                      {content.giftCards.paymentBillingEmailLabel}
                    </span>{' '}
                    <span>{customer.email}</span>
                  </div>
                  {giftCardRecipient.message && (
                    <div className='mt-1'>
                      <span className='fw-bold'>
                        {content.giftCards.paymentRecipientMessageLabel}
                      </span>{' '}
                      <span>{giftCardRecipient.message}</span>
                    </div>
                  )}
                </div>
                <div className='mt-4'>
                  <Button
                    onClick={() => navigate(-1)}
                    variant='link'
                    className='btn-edit b text-center text-uppercase'
                  >
                    {content.giftCards.paymentEditText}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {shouldShowGuestMarketingOptions && (
        <ContainedRow>
          <GuestMarketing />
        </ContainedRow>
      )}

      <ContainedRow>
        <TermsAndConditions
          isPageValidated={isPageValidated}
          onEdit={resetHostedPayment}
        />
      </ContainedRow>

      {!isReadyForPayment && (
        <ContainedRow>
          <ActionButton
            onClick={handleValidatePage}
            showIcon
            showWarningMessage={isPageValidated && !isReadyForPayment}
            warningMessage={content.payment.formErrorsMessage}
            warningTitle={content.payment.formErrorsSubTitle}
            mb='mb-0'
            variant='primary'
          >
            {content.continueToPaymentText}
          </ActionButton>
        </ContainedRow>
      )}

      {isReadyForPayment && (
        <PaymentDesignWrapper
          giftcardEnabled={false}
          loyaltyPaymentEnabled={false}
          creditCardType={creditCardType}
          showPaymentForm={showPaymentForm}
          showGiftCardForm={false}
          isPageValidated={isPageValidated}
          handleValidatePage={handleValidatePage}
          setPaymentFormVisibility={setPaymentFormVisibility}
          setGiftCardFormVisibility={undefined}
          setCreditCardType={setCreditCardType}
        />
      )}
    </div>
  );
};

export default GiftCardPayment;

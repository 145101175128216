import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import {
  getFullAddress,
  splitDeliveryItemFromConcessions,
} from '../../../services/GiftCardHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAllConcessions,
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectIsCustomerReadyForPayment,
  selectPhysicalGiftCardRecipient,
  selectSelectedGiftCards,
  selectShouldShowGuestMarketingOptions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import PaymentSummaryRowPhysical from '../../common/concessionsonlycommon/physicalgiftcard/PaymentSummaryRowPhysical';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import GuestMarketing from '../../common/payment/others/GuestMarketing';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';

const GiftMembershipPayment: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const bookingData = useSelector(selectBookingData);
  const concessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const shouldShowGuestMarketingOptions = useSelector(
    selectShouldShowGuestMarketingOptions
  );
  const showNewsLetterSignUp =
    config.payment.showGuestNewsletterSignUpCheckbox &&
    !bookingData.isUserValidated;
  const canLaunchPayment = isCustomerReadyForPayment && !showNewsLetterSignUp;

  const [isPageValidated, setIsPageValidated] = useState(!!canLaunchPayment);
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const [creditCardType, setCreditCardType] = useState('');
  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;

  useConfirmationPageRedirect();

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setShowPaymentForm(nextShowPaymentForm);
  };

  const handleValidatePage = () => {
    setIsPageValidated(true);
  };

  const resetHostedPayment = () => {
    setIsPageValidated(false);
    dispatch(actionCreators.removeHostedPayments());
  };

  const { listWithNoDeliveryItem, deliveryItem } =
    splitDeliveryItemFromConcessions(concessions, selectedGiftCards);

  if (!content.giftMembership || !config || !bookingData) return null;

  return (
    <div className='payment text-center' data-testid='payment'>
      <div className='giftcards'>
        <Row className='text-center'>
          <Col xs={12}>
            <Heading size={1}>{content.giftMembership.stepTwoHeading}</Heading>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='contained'>
            <Heading size={2} className='mt-3 text-center'>
              {content.giftMembership.orderSummaryHeading}
            </Heading>
            <RichText text={content.giftMembership.orderSummaryRichText} />
          </Col>
        </Row>
        <RecaptchaText />
        <Row className='px-2 px-md-0'>
          <Col md={10} className='giftcard-rows-container summary-rows mt-4'>
            {listWithNoDeliveryItem.map((x, i) => (
              <PaymentSummaryRowPhysical concession={x} key={`${x.id}_${i}`} />
            ))}
            {deliveryItem && (
              <PaymentSummaryRowPhysical concession={deliveryItem} />
            )}
            <Row className='recipient-details summary text-start tiny pt-3 pb-4'>
              <Col xs={12} className='contained'>
                <div className='detail'>
                  <div>
                    <span className='fw-bold'>
                      {content.giftMembership.orderSummaryNameLabel}
                    </span>{' '}
                    <span>{physicalGiftCardRecipient.name}</span>
                  </div>
                  <div>
                    <span className='fw-bold'>
                      {content.giftMembership.orderSummaryAddressLabel}
                    </span>{' '}
                    <span>
                      {getFullAddress(
                        physicalGiftCardRecipient.addressLineOne,
                        physicalGiftCardRecipient.addressLineTwo,
                        physicalGiftCardRecipient.city,
                        physicalGiftCardRecipient.postcode
                      )}
                    </span>
                  </div>

                  {physicalGiftCardRecipient.message && (
                    <div className='mt-1'>
                      <span className='fw-bold'>
                        {content.giftMembership.orderSummaryMessageLabel}
                      </span>{' '}
                      <span>{physicalGiftCardRecipient.message}</span>
                    </div>
                  )}

                  <div className='mt-1'>
                    <span className='fw-bold'>
                      {content.giftCards.paymentBillingNameLabel}
                    </span>{' '}
                    <span>{customer.name}</span>
                  </div>

                  <div className='mt-0'>
                    <span className='fw-bold'>
                      {content.giftCards.paymentBillingEmailLabel}
                    </span>{' '}
                    <span>{customer.email}</span>
                  </div>
                </div>

                <div className='mt-4'>
                  <Button
                    onClick={() => navigate(-1)}
                    variant='link'
                    className='btn-edit b text-center text-uppercase'
                  >
                    {content.giftMembership.orderSummaryEditButtonText}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {shouldShowGuestMarketingOptions && (
        <Row>
          <Col xs={12} className='contained'>
            <GuestMarketing />
          </Col>
        </Row>
      )}

      <ContainedRow>
        <TermsAndConditions
          isPageValidated={isPageValidated}
          onEdit={resetHostedPayment}
        />
      </ContainedRow>

      {!isReadyForPayment && (
        <ContainedRow>
          <ActionButton
            onClick={handleValidatePage}
            showIcon
            showWarningMessage={isPageValidated && !isReadyForPayment}
            warningMessage={content.payment.formErrorsMessage}
            warningTitle={content.payment.formErrorsSubTitle}
            mb='mb-0'
            variant='primary'
          >
            {content.continueToPaymentText}
          </ActionButton>
        </ContainedRow>
      )}

      {isReadyForPayment && (
        <PaymentDesignWrapper
          giftcardEnabled={false}
          loyaltyPaymentEnabled={false}
          creditCardType={creditCardType}
          showPaymentForm={showPaymentForm}
          showGiftCardForm={false}
          isPageValidated={isPageValidated}
          handleValidatePage={handleValidatePage}
          setPaymentFormVisibility={setPaymentFormVisibility}
          setCreditCardType={setCreditCardType}
        />
      )}
    </div>
  );
};

export default GiftMembershipPayment;

import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Concession, OrderDelivery, Deal } from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { PEACH_CODES } from '../../../constants';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useMaintainDeliveryItemCount } from '../../../hooks/useMaintainDeliveryItemCount';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import { setGiftCardConcessions } from '../../../services/GiftCardHelpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectLoading,
  selectPhysicalGiftCardRecipient,
  selectQueryString,
  selectToken,
  selectSelectedGiftCards,
  selectPhysicalGiftCardGroup,
  selectGiftCardsAdded,
  selectAllConcessions,
  selectContentPhysicalGiftcards,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import DeliveryDetails from '../../common/concessionsonlycommon/physicalgiftcard/DeliveryDetailsPhysical';
import SinglePhysicalGiftCardOptionRow from '../../common/concessionsonlycommon/physicalgiftcard/SinglePhysicalGiftCardOptionRow';
import Heading from '../../common/heading/Heading';
import RichText from '../../common/richtext/RichText';

const PhysicalGiftCardSelect: React.FC = () => {
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const boostNavigate = useBoostNavigate();

  const bookingData = useSelector(selectBookingData);
  const concessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const loading = useSelector(selectLoading);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );
  const queryString = useSelector(selectQueryString);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const physicalGiftCardGroup = useSelector(selectPhysicalGiftCardGroup);
  const giftCardsAddedToPos = useSelector(selectGiftCardsAdded);
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);
  const [showRecipientForm, setShowRecipientForm] = useState<boolean>(false);
  const [validateForm, setValidateForm] = useState<boolean>(false);

  const selectedGiftCardsList = selectedGiftCards.list.filter(
    (concession) => !concession.isDeliveryItem
  );

  const hasGiftCards = selectedGiftCardsList
    ? selectedGiftCardsList.some((x: Concession) => x.quantity > 0)
    : false;

  const totalCardsSelected = selectedGiftCardsList.reduce(
    (a: number, b: Concession) => a + (b['quantity'] || 0),
    0
  );

  useMaintainDeliveryItemCount(totalCardsSelected);

  useAnalyticsTrackOnce(TrackingEvent.GIFTCARD_LANDING);

  // gets Data
  useEffect(() => {
    const getConcessionData = async () => {
      dispatch(actionCreators.setLoading(true));
      const path = `api/GiftCard/GetPhysicalGiftCards/${bookingData.circuitId}/${bookingData.cinemaId}`;
      const response = await backend.get(path);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        const deals = response.content.deals?.filter(
          (x: Deal) => x.isConcessionRelated && !x.isTicketRelated
        );
        if (deals) {
          dispatch(actionCreators.setDeals(deals));
        }
        setGiftCardConcessions(
          response.content.listConcessionGrouping,
          queryString,
          dispatch
        );
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!concessions && bookingData) {
      getConcessionData();
    }
  }, [bookingData, concessions, dispatch, queryString]);

  const addGiftCardsSelectionToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasGiftCards || giftCardsAddedToPos) {
      const orderDelivery: OrderDelivery = {
        isGift: false,
        isGiftWrapped: false,
        giftMessage: physicalGiftCardRecipient.message,
        deliveryAddress: {
          name: physicalGiftCardRecipient.name,
          address1: physicalGiftCardRecipient.addressLineOne,
          address2: physicalGiftCardRecipient.addressLineTwo,
          town: physicalGiftCardRecipient.city,
          postcode: physicalGiftCardRecipient.postcode,
          state: physicalGiftCardRecipient.state ?? '',
        },
        billingAddress: {
          name: customer.name,
          email: customer.email,
        },
      };

      selectedGiftCards?.list.forEach((c: Concession) => {
        c.orderDelivery = orderDelivery;
      });

      const data = {
        dataToken: dataToken,
        concessions: selectedGiftCards
          ? selectedGiftCards.list.filter((c: Concession) => !c.hidden)
          : [],
      };
      const response = await backend.post(
        `api/GiftCard/AddPhysicalGiftCards`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setGiftCardsAddedToPos(hasGiftCards));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        dispatch(
          actionCreators.setGrandTotalWithDiscount(
            response.content.grandTotalWithDiscount,
            response.content.totalDiscount
          )
        );
        boostNavigate.navigateToNextStep();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            PEACH_CODES.concessionsCouldNotBeAdded
          )
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleContinueToPaymentClick = () => {
    if (!validateForm) {
      setValidateForm(true);
    }
    addGiftCardsSelectionToPos();
  };

  const getPhysicalGiftCardsRows = () => {
    return physicalGiftCardGroup?.items?.map((concession: Concession) => (
      <SinglePhysicalGiftCardOptionRow
        {...concession}
        key={concession.id}
        defaultImage={content.physicalGiftCards.physicalGiftCardDefaultImage}
        totalCardsSelected={totalCardsSelected}
      />
    ));
  };

  if (!contentPhysicalGiftCards || !config) return null;

  return (
    <div className='giftcards' data-testid='giftcards'>
      <Row className='text-center'>
        <Col>
          <Heading size={1}>{contentPhysicalGiftCards.stepOneHeading}</Heading>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className='contained'>
          <Heading className='mt-3 text-center' size={2}>
            1. {contentPhysicalGiftCards.selectGiftCardsHeading}
          </Heading>
          <RichText text={contentPhysicalGiftCards.selectGiftCardsRichText} />
        </Col>
      </Row>

      {physicalGiftCardGroup && physicalGiftCardGroup.items.length > 0 ? (
        <Row>
          <Col>
            <Row>
              <Col md={10} className='giftcard-rows-container mt-4'>
                {getPhysicalGiftCardsRows()}
              </Col>
            </Row>

            {!showRecipientForm ? (
              <ActionButton
                disabled={!hasGiftCards}
                mt='mt-5'
                mx='mx-3'
                onClick={() => setShowRecipientForm(true)}
                sticky={false}
                showIcon={true}
                contained
                variant='primary'
              >
                {contentPhysicalGiftCards.continueToDetailsButtonText}
              </ActionButton>
            ) : (
              <>
                <DeliveryDetails isPageValidated={validateForm} />
                <Col xs={12} className='contained'>
                  <RichText
                    text={contentPhysicalGiftCards.additionalRichText}
                  />
                </Col>

                <ActionButton
                  mt='mt-5'
                  mx='mx-3'
                  sticky={false}
                  showIcon={true}
                  contained
                  onClick={handleContinueToPaymentClick}
                  disabled={
                    !hasGiftCards ||
                    !physicalGiftCardRecipient.isValid ||
                    !customer.isValid
                  }
                  stickyMobileDesktop={false}
                  variant='primary'
                >
                  {contentPhysicalGiftCards.continueToPaymentButtonText}
                </ActionButton>
              </>
            )}
          </Col>
        </Row>
      ) : (
        !loading && (
          <Row>
            <Col xs={12} className='contained mt-1'>
              <div className='warning-container mt-3 p-3'>
                <p>
                  {contentPhysicalGiftCards.noPhysicalGiftCardsAvailableText}
                </p>
              </div>
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default PhysicalGiftCardSelect;

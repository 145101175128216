import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Concession } from '../../../@types/modelTypes';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import SummaryTierItem from '../../common/loyalty/SummaryTierItem';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';

const LoyaltyUpgradePayment: React.FC = () => {
  const navigate = useNavigate();
  useValidateConcessionsJourney();
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const [validateForms, setValidateForms] = useState(false);
  const [creditCardType, setCreditCardType] = useState('');

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setValidateForms(!nextShowPaymentForm);
  };

  useConfirmationPageRedirect();

  const concession: Concession | undefined = selectedConcessions.list.find(
    (c: Concession) => c.quantity === 1
  );

  if (!content || !config || !bookingData) return null;

  return (
    <div className='loyalty payment text-center' data-testid='loyalty-payment'>
      <div className='giftcards'>
        <Row className='text-center'>
          <Col xs={12}>
            <Heading size={1}>{content.loyalty.upgradeMainTitle}</Heading>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='contained'>
            <Heading size={2} className='mt-3 text-center'>
              3. {content.loyalty.paymentHeading}
            </Heading>
            <RichText
              text={content.loyalty.upgradePaymentRichText}
              className='text-start'
            />
          </Col>
        </Row>
        <RecaptchaText />
        <div className='loyalty-rows-container mt-3'>
          <div className='separator' />
          <Row className='summary-row'>
            {concession ? (
              <Col xs={12}>
                <SummaryTierItem
                  {...concession}
                  key={concession.id}
                  defaultImage={
                    content.physicalGiftCards.physicalGiftCardDefaultImage
                  }
                />
              </Col>
            ) : null}
          </Row>
          <Row className='text-start tiny pb-3'>
            <Col xs={12} className='contained'>
              <div className='detail mt-1'>
                <div className='mt-1'>
                  <span className='fw-bold'>
                    {content.loyalty.paymentBillingNameLabel}
                  </span>{' '}
                  <span>{customer.name}</span>
                </div>

                <div>
                  <span className='fw-bold'>
                    {content.loyalty.paymentBillingEmailLabel}
                  </span>{' '}
                  <span>{customer.email}</span>
                </div>
              </div>
              <div className='mt-4'>
                <Button
                  onClick={() => navigate(-1)}
                  variant='link'
                  className='btn-edit b text-center text-uppercase'
                >
                  {content.loyalty.paymentEditText}
                </Button>
              </div>
            </Col>
          </Row>
          <div className='separator' />
        </div>
      </div>

      <ContainedRow classNameCol='text-start'>
        <TermsAndConditions isPageValidated={validateForms} />
      </ContainedRow>

      <PaymentDesignWrapper
        giftcardEnabled={false}
        loyaltyPaymentEnabled={false}
        creditCardType={creditCardType}
        showPaymentForm={true}
        showGiftCardForm={false}
        isPageValidated={validateForms}
        handleValidatePage={() => {
          setValidateForms(true);
        }}
        setPaymentFormVisibility={setPaymentFormVisibility}
        setGiftCardFormVisibility={undefined}
        setCreditCardType={setCreditCardType}
      />
    </div>
  );
};

export default LoyaltyUpgradePayment;

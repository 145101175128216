/* eslint-disable @typescript-eslint/no-explicit-any */
import * as ActionTypes from './Actions';

import {
  ChangeDeliveryItemContext,
  ChangeGiftCardByIdContext,
  QuantitySelectorContext,
} from '../@types/actionTypes';
import { Config, LanguageCulture } from '../@types/configTypes';
import { Content } from '../@types/contentTypes';
import { DeliveryPreference, PaymentProvidersEnum } from '../@types/enums';
import {
  AppliedDeal,
  BookingFeeStrategy,
  Concession,
  ConcessionGrouping,
  ConfirmationSession,
  Customer,
  Deal,
  DealInTicketsStep,
  DeliveryWindow,
  EmbargoData,
  GiftCard,
  GiftCardRecipient,
  GuestMarketing,
  HostedPayment,
  Payment,
  PhysicalGiftCardRecipient,
  SeasonPassMovie,
  SeasonPassMovieSession,
  SeatMapSeat,
  SelectSeatsModel,
  SelectedSeasonPass,
  Showtime,
  SubmitMakePaymentProps,
  TicketCredit,
  TicketTypeModel,
  TicketTypes,
  Tickets,
  ValidatedMemberCard,
  Voucher,
} from '../@types/modelTypes';
import { ConsentData } from '../services/DidomiHelpers';

export const actionCreators = {
  setLoading: (loading: boolean) => ({
    type: ActionTypes.SET_LOADING,
    loading,
  }),
  clearSession: () => ({ type: ActionTypes.CLEAR_SESSION }),
  initializeSession: (session: any) => ({
    type: ActionTypes.INITIALIZE_SESSION,
    session,
  }),
  setToken: (token: string) => ({ type: ActionTypes.SET_TOKEN, token }),
  setTicketTypes: (ticketTypes: TicketTypes | null) => ({
    type: ActionTypes.SET_TICKET_TYPES,
    ticketTypes,
  }),
  setAvailableTickets: (availablePosTickets: Tickets) => ({
    type: ActionTypes.SET_AVAILABLE_TICKETS,
    availablePosTickets,
  }),
  addVoucherTickets: (availablePosTickets: Tickets) => ({
    type: ActionTypes.ADD_VOUCHER_TICKETS,
    availablePosTickets,
  }),
  setHasThirdPartyMemberTickets: (bool: boolean) => ({
    type: ActionTypes.SET_HAS_THIRD_PARTY_MEMBER_TICKETS,
    bool,
  }),
  setSelectedDonation: (isCustomAmount: boolean, amount: number) => ({
    type: ActionTypes.SET_SELECTED_DONATION,
    isCustomAmount,
    amount,
  }),
  setDonation: (amount: number) => ({
    type: ActionTypes.SET_DONATION,
    amount,
  }),
  setSelectedGratuity: (
    isCustomAmount: boolean,
    amount: number,
    percentage: number
  ) => ({
    type: ActionTypes.SET_SELECTED_GRATUITY,
    isCustomAmount,
    amount,
    percentage,
  }),
  setLoyaltyRedemptionRewards: (value: number) => ({
    type: ActionTypes.SET_LOYALTY_REDEMPTION_REWARDS,
    value,
  }),
  setLoyaltyRecognitionNumber: (value: string) => ({
    type: ActionTypes.SET_LOYALTY_RECOGNITION_NUMBER,
    value,
  }),
  setBookingFeeAndTax: (fee: number, tax: number) => ({
    type: ActionTypes.SET_BOOKING_FEE_AND_TAX,
    fee,
    tax,
  }),
  setBookingFee: (fee: number) => ({
    type: ActionTypes.SET_BOOKING_FEE,
    fee,
  }),
  setBookingFeeStrategy: (bookingFeeStrategy: BookingFeeStrategy) => ({
    type: ActionTypes.SET_BOOKING_FEE_STRATEGY,
    bookingFeeStrategy,
  }),
  setSeatsModel: (model: SelectSeatsModel | undefined) => ({
    type: ActionTypes.SET_SEATS_MODEL,
    model,
  }),
  setEmbargoData: (value: EmbargoData) => ({
    type: ActionTypes.SET_EMBARGO_DATA,
    value,
  }),
  setError: (msg: string, peachCode?: number) => ({
    type: ActionTypes.SET_ERROR,
    msg: peachCode ? `${msg} (${peachCode})` : msg,
    peachCode,
  }),
  setMaxRetriesExceeded: (maxRetriesExceeded: boolean) => ({
    type: ActionTypes.SET_ALLOW_PAYMENT,
    maxRetriesExceeded,
  }),
  clearError: () => ({ type: ActionTypes.CLEAR_ERROR }),
  addSeat: (seat: SeatMapSeat) => ({ type: ActionTypes.ADD_SEAT, seat }),
  removeSeat: (seat: SeatMapSeat) => ({ type: ActionTypes.REMOVE_SEAT, seat }),
  removeAllSeats: () => ({ type: ActionTypes.REMOVE_ALL_SEATS }),
  setSelectedSeats: (selectedSeats: SeatMapSeat[]) => ({
    type: ActionTypes.SET_SELECTED_SEATS,
    selectedSeats,
  }),
  setConfirmation: (confirmation: ConfirmationSession) => ({
    type: ActionTypes.SET_CONFIRMATION,
    confirmation,
  }),
  setCustomerDetails: (customer: Customer) => ({
    type: ActionTypes.SET_CUSTOMER_DETAILS,
    customer,
  }),
  setPaymentDetails: (payment: Payment) => ({
    type: ActionTypes.SET_PAYMENT_DETAILS,
    payment,
  }),
  setHostedPayment: (
    paymentProvider: PaymentProvidersEnum,
    hostedPayment: HostedPayment | null,
    guestSessionToken?: string
  ) => ({
    type: ActionTypes.SET_HOSTED_PAYMENT,
    paymentProvider,
    hostedPayment,
    guestSessionToken,
  }),
  removeHostedPayments: () => ({
    type: ActionTypes.REMOVE_HOSTED_PAYMENT,
  }),
  addGiftCard: (giftCard: GiftCard) => ({
    type: ActionTypes.ADD_GIFTCARD,
    giftCard,
  }),
  removeGiftCard: (giftCard: GiftCard) => ({
    type: ActionTypes.REMOVE_GIFTCARD,
    giftCard,
  }),
  removeAllGiftCards: () => ({
    type: ActionTypes.REMOVE_ALL_GIFTCARDS,
  }),
  setCountDown: (secondsToExpiration: number) => ({
    type: ActionTypes.SET_COUNTDOWN,
    secondsToExpiration,
  }),
  setCountDownExpired: (value: boolean) => ({
    type: ActionTypes.SET_COUNTDOWN_EXPIRED,
    value,
  }),
  setCircuitConfig: (config: Config) => ({
    type: ActionTypes.SET_CIRCUIT_CONFIG,
    config,
  }),
  setCircuitContent: (content: Content) => ({
    type: ActionTypes.SET_CIRCUIT_CONTENT,
    content,
  }),
  setRequestData: (requestData: string) => ({
    type: ActionTypes.SET_REQUEST_DATA,
    requestData,
  }),
  setSendAnalytics: (value: boolean) => ({
    type: ActionTypes.SET_SEND_ANALYTICS,
    value,
  }),
  setQueryString: (value: string) => ({
    type: ActionTypes.SET_QUERY_STRING,
    value,
  }),
  setStep: (step: number) => ({ type: ActionTypes.SET_STEP, step }),
  setKioskSubStep: (kioskSubStep: 'fab-landing' | 'fab' | 'egc') => ({
    type: ActionTypes.SET_KIOSK_SUBSTEP,
    kioskSubStep,
  }),
  setKioskActiveCategory: (kioskActiveCategory: number) => ({
    type: ActionTypes.SET_KIOSK_ACTIVE_CATEGORY,
    kioskActiveCategory,
  }),
  setJourneyType: (journeyType: string) => ({
    type: ActionTypes.SET_JOURNEY_TYPE,
    journeyType,
  }),
  setRefund: (data: any) => ({ type: ActionTypes.SET_REFUND, data }),
  setConcessions: (concessionGroupings: ConcessionGrouping[] | null) => ({
    type: ActionTypes.SET_CONCESSIONS,
    concessionGroupings,
  }),
  addConcessionWithMods: (concession: Concession, isFaB: boolean) => ({
    type: ActionTypes.ADD_CONCESSION,
    concession,
    isFaB,
  }),
  removeConcessionWithMods: (
    concession: Concession,
    isFaB: boolean,
    isRemove?: boolean
  ) => ({
    type: ActionTypes.REMOVE_CONCESSION,
    concession,
    isFaB,
    isRemove,
  }),
  changeConcessionByIndex: (
    concession: Concession,
    index: number,
    isFaB: boolean
  ) => ({
    type: ActionTypes.CHANGE_CONCESSION_BY_INDEX,
    concession,
    index,
    isFaB,
  }),
  changeGiftCardById: (
    concession: Concession,
    context: ChangeGiftCardByIdContext
  ) => ({
    type: ActionTypes.CHANGE_GIFTCARD_BY_ID,
    concession,
    context,
  }),
  changeDeliveryItem: (
    concession: Concession,
    context: ChangeDeliveryItemContext
  ) => ({
    type: ActionTypes.CHANGE_DELIVERY_ITEM,
    concession,
    context,
  }),
  changeDeliveryItemId: (deliveryItemId: string) => ({
    type: ActionTypes.CHANGE_DELIVERY_ITEM_ID,
    deliveryItemId,
  }),
  setLoyaltyTierSelection: (
    concession: Concession,
    context: QuantitySelectorContext
  ) => ({
    type: ActionTypes.SET_LOYALTY_TIER_SELECTION,
    concession,
    context,
  }),
  clearConcessions: (isFaB: boolean) => ({
    type: ActionTypes.CLEAR_CONCESSIONS,
    isFaB,
  }),
  clearAvailableConcessions: () => ({
    type: ActionTypes.CLEAR_AVAILABLE_CONCESSIONS,
  }),
  setConcessionsAddedToPos: (concessionsAdded: boolean | undefined) => ({
    type: ActionTypes.SET_CONCESSIONS_ADDED,
    concessionsAdded,
  }),
  setGiftCardsAddedToPos: (giftCardsAdded: boolean | undefined) => ({
    type: ActionTypes.SET_GIFTCARDS_ADDED,
    giftCardsAdded,
  }),
  setLanguage: (
    content: Content,
    dazzlerContent: any,
    token: string,
    culture: LanguageCulture
  ) => ({
    type: ActionTypes.SET_LANGUAGE,
    content,
    dazzlerContent,
    token,
    culture,
  }),
  setGiftCardRecipient: (giftCardRecipient: GiftCardRecipient) => ({
    type: ActionTypes.SET_GIFTCARD_RECIPIENT,
    giftCardRecipient,
  }),
  setVoucherRecipient: (voucherRecipient: GiftCardRecipient) => ({
    type: ActionTypes.SET_VOUCHER_RECIPIENT,
    voucherRecipient,
  }),
  setPhysicalGiftCardRecipient: (
    physicalGiftCardRecipient: PhysicalGiftCardRecipient
  ) => ({
    type: ActionTypes.SET_PHYSICAL_GIFTCARD_RECIPIENT,
    physicalGiftCardRecipient,
  }),
  setShowtimes: (showtimes: Showtime[] | undefined) => ({
    type: ActionTypes.SET_SHOWTIMES,
    showtimes,
  }),
  setConcessionsJourneyParentBookingRef: (bookingRef: string) => ({
    type: ActionTypes.SET_CONCESSIONS_JOURNEY_PARENT_BOOKING_REF,
    bookingRef,
  }),
  setDeliveryPreference: (deliveryPreference: DeliveryPreference | null) => ({
    type: ActionTypes.SET_DELIVERY_PREFERENCE,
    deliveryPreference,
  }),
  setSelectedDeliveryWindow: (
    selectedDeliveryWindow: DeliveryWindow | null
  ) => ({
    type: ActionTypes.SET_SELECTED_DELIVERY_WINDOW,
    selectedDeliveryWindow,
  }),
  setDeliveryWindows: (deliveryWindows: DeliveryWindow[]) => ({
    type: ActionTypes.SET_DELIVERY_WINDOWS,
    deliveryWindows,
  }),
  setGuestMarketing: (guestMarketing: GuestMarketing) => ({
    type: ActionTypes.SET_GUEST_MARKETING,
    guestMarketing,
  }),
  setGuestNewsletterSignUp: (guestNewsletterSignUp: boolean) => ({
    type: ActionTypes.SET_GUEST_NEWSLETTER_SIGN_UP,
    guestNewsletterSignUp,
  }),
  setGuestSessionToken: (guestSessionToken: string | undefined) => ({
    type: ActionTypes.SET_GUEST_SESSION_TOKEN,
    guestSessionToken,
  }),

  setDataLayerPopulated: (dataLayerPopulated: boolean) => ({
    type: ActionTypes.SET_DATALAYER_POPULATED,
    dataLayerPopulated,
  }),
  setHasVoucherApplied: (hasVoucherApplied: boolean | undefined) => ({
    type: ActionTypes.SET_HAS_VOUCHER_APPLIED,
    hasVoucherApplied,
  }),
  setGrandTotalWithDiscount: (
    grandTotalWithDiscount: number | undefined,
    totalDiscount: number
  ) => ({
    type: ActionTypes.SET_GRAND_TOTAL_WITH_DISCOUNT,
    grandTotalWithDiscount,
    totalDiscount,
  }),
  setDealsInTicketsStep: (dealsInTicketsStep: DealInTicketsStep[]) => ({
    type: ActionTypes.SET_DEALS_IN_TICKETS_STEP,
    dealsInTicketsStep,
  }),
  setAppliedDealsWithDiscount: (
    appliedDeals: AppliedDeal[],
    totalDiscount: number
  ) => ({
    type: ActionTypes.SET_APPLIED_DEALS_WITH_DISCOUNT,
    appliedDeals,
    totalDiscount,
  }),
  setTotalDiscount: (totalDiscount: number) => ({
    type: ActionTypes.SET_TOTAL_DISCOUNT,
    totalDiscount,
  }),
  setDeals: (deals: Deal[]) => ({
    type: ActionTypes.SET_DEALS,
    deals,
  }),
  addVoucher: (voucher: Voucher) => ({
    type: ActionTypes.ADD_VOUCHER,
    voucher,
  }),
  removeVoucher: (voucher: Voucher) => ({
    type: ActionTypes.REMOVE_VOUCHER,
    voucher,
  }),
  setHostedPaymentInProgress: (isHostedPaymentInProgress: boolean) => ({
    type: ActionTypes.SET_HOSTED_PAYMENT_IN_PROGRESS,
    isHostedPaymentInProgress,
  }),
  setConsent: (consent: ConsentData) => ({
    type: ActionTypes.SET_CONSENT,
    consent,
  }),
  setTermsAndConditionsChecked: (checked: boolean) => ({
    type: ActionTypes.SET_TERMS_AND_CONDITIONS_CHECKED,
    checked,
  }),
  setCartSummaryOpen: (isCartSummaryOpen: boolean) => ({
    type: ActionTypes.SET_CART_SUMMARY_OPEN,
    isCartSummaryOpen,
  }),
  addCeaCard: (ceaCard: string) => ({
    type: ActionTypes.ADD_CEA_CARD,
    ceaCard,
  }),
  removeCeaCard: (ceaCard: string) => ({
    type: ActionTypes.REMOVE_CEA_CARD,
    ceaCard,
  }),
  clearCeaCards: () => ({
    type: ActionTypes.CLEAR_CEA_CARDS,
  }),
  addSingleTicket: (ticketTypeModel: TicketTypeModel) => ({
    type: ActionTypes.ADD_SINGLE_TICKET,
    ticketTypeModel,
  }),
  removeSingleTicket: (ticketTypeModel: TicketTypeModel) => ({
    type: ActionTypes.REMOVE_SINGLE_TICKET,
    ticketTypeModel,
  }),
  validateDynamicBasket: (payload: any) => ({
    type: ActionTypes.VALIDATE_DYNAMIC_BASKET,
    payload,
  }),
  validateCeaCard: (payload: any) => ({
    type: ActionTypes.VALIDATE_CEA_CARD,
    payload,
  }),
  addBasketTickets: (payload: any) => ({
    type: ActionTypes.ADD_BASKET_TICKETS,
    payload,
  }),
  setTicketCredits: (payload: TicketCredit[] | undefined) => ({
    type: ActionTypes.SET_TICKET_CREDITS,
    payload,
  }),
  setTicketCreditsAvailable: (payload: number) => ({
    type: ActionTypes.SET_TICKET_CREDITS_AVAILABLE,
    payload,
  }),
  addMemberCard: (memberCardAndTicket: ValidatedMemberCard) => ({
    type: ActionTypes.ADD_MEMBER_CARD,
    memberCardAndTicket,
  }),
  removeMemberCard: (memberCardNumber: string) => ({
    type: ActionTypes.REMOVE_MEMBER_CARD,
    memberCardNumber,
  }),
  clearMemberCards: () => ({
    type: ActionTypes.CLEAR_MEMBER_CARDS,
  }),
  initializeAmazonPayPayment: (payload: any) => ({
    type: ActionTypes.INITIALIZE_AMAZONPAY_PAYMENT,
    payload,
  }),
  setSelectedPaymentProvider: (
    selectedPaymentProvider: PaymentProvidersEnum | null
  ) => ({
    type: ActionTypes.SET_SELECTED_PAYMENT_PROVIDER,
    selectedPaymentProvider,
  }),
  submitMakePayment: (payload: SubmitMakePaymentProps) => ({
    type: ActionTypes.SUBMIT_MAKE_PAYMENT_REQUEST,
    payload,
  }),
  adyenInitiatePayment: (payload: any) => ({
    type: ActionTypes.ADYEN_INITIATE_PAYMENT,
    payload,
  }),
  adyenSubmitAdditionalDetails: (payload: any) => ({
    type: ActionTypes.ADYEN_SUBMIT_ADDITIONAL_DETAILS,
    payload,
  }),
  hostedPaymentRequest: (payload: any) => ({
    type: ActionTypes.HOSTED_PAYMENT_REQUEST,
    payload,
  }),
  adyenDropinHostedPayment: (payload: any) => ({
    type: ActionTypes.ADYEN_DROPIN_HOSTED_PAYMENT,
    payload,
  }),
  setDeliveryOptionBasedOnSeatingArea: (payload: boolean) => ({
    type: ActionTypes.SET_DELIVERY_OPTION_BASED_ON_SEATING_AREA,
    payload,
  }),
  removeUncollectibleConcessions: () => ({
    type: ActionTypes.REMOVE_UNCOLLECTIBLE_CONCESSIONS,
  }),
  setSelectedSeasonPass: (payload: SelectedSeasonPass) => ({
    type: ActionTypes.SET_SELECTED_SEASON_PASS,
    payload,
  }),
  setSeasonPassMovies: (payload: SeasonPassMovie[]) => ({
    type: ActionTypes.SET_SEASON_PASS_MOVIES,
    payload,
  }),
  setSelectedSeasonPassSessions: (payload: {
    movie: SeasonPassMovie;
    session: SeasonPassMovieSession;
  }) => ({
    type: ActionTypes.SET_SELECTED_SEASON_PASS_SESSIONS,
    payload,
  }),
  removeSelectedSeasonPassSession: (payload: SeasonPassMovieSession) => ({
    type: ActionTypes.REMOVE_SELECTED_SEASON_PASS_SESSION,
    payload,
  }),
  setDidomiReady: () => ({
    type: ActionTypes.SET_DIDOMI_READY,
  }),
};

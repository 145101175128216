/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react';

import { Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import { TicketTypeModel } from '../../../@types/modelTypes';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateSeasonPassJourney } from '../../../hooks/useValidateSeasonPassJourney';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectUseCalculatedBookingFeeStrategy,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import SelectedSeasonPassImage from '../../common/seasonpass/SelectedSeasonPassImage';
import SelectedSeasonPassTitleAndLocation from '../../common/seasonpass/SelectedSeasonPassTitleAndLocation';
import TicketSelector from '../../common/tickets/TicketSelector';

const SeasonPassTickets: React.FC = () => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();

  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const {
    useBookingFeeStrategy,
    calculatedBookingFee,
    calculatedBookingFeeTax,
  } = useSelector(selectUseCalculatedBookingFeeStrategy);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);
  const selectedSeasonPassItem = selectedSeasonPass?.seasonPassItem;
  const selectedSeasonPassTickets = selectedSeasonPassItem?.ticketTypes;

  const stepRequiredDataAreValid =
    content?.seasonPass && selectedSeasonPassItem;

  useValidateSeasonPassJourney(!!stepRequiredDataAreValid);

  useEffect(() => {
    if (useBookingFeeStrategy) {
      dispatch(
        actionCreators.setBookingFeeAndTax(
          calculatedBookingFee,
          calculatedBookingFeeTax
        )
      );
    }
  }, [
    calculatedBookingFee,
    calculatedBookingFeeTax,
    dispatch,
    selectedSeasonPassItem?.ticketTypes,
    useBookingFeeStrategy,
  ]);

  if (!stepRequiredDataAreValid) {
    return null;
  }

  const disableContinueButton =
    (selectedSeasonPassTickets?.reduce(
      (acc, ticket) => acc + ticket.quantity,
      0
    ) ?? 0) == 0;

  const handleClick = () => {
    boostNavigate.navigateToNextStep({
      appendCinemaAndSessionIdsFromUrl: true,
    });
  };

  const handleTicketButtonClick = async (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => {
    const seasonPassTicketType = selectedSeasonPassTickets.find(
      (x) => x.ticketTypeId === parseInt(ticketTypeId, 10)
    );
    if (!seasonPassTicketType) {
      return;
    }

    if (context === 'add') {
      seasonPassTicketType.quantity += 1;
    } else if (context === 'remove') {
      seasonPassTicketType.quantity += -1;
    }

    const updatedSeasonPass = {
      ...selectedSeasonPassItem,
      ticketTypes: selectedSeasonPassTickets?.map((ticketType) =>
        ticketType.ticketTypeId === seasonPassTicketType.ticketTypeId
          ? { ...ticketType, quantity: seasonPassTicketType.quantity }
          : ticketType
      ),
    };

    selectedSeasonPass.seasonPassItem = updatedSeasonPass;

    dispatch(actionCreators.setSelectedSeasonPass(selectedSeasonPass));
  };

  const mappedSelectedSeasonPassTickets: TicketTypeModel[] =
    selectedSeasonPassTickets?.map((t) => ({
      areaCategoryCode: '',
      displayName: t.displayName,
      id: t.ticketTypeId?.toString(),
      isAllocatableSeating: false,
      isMemberTicket: false,
      isPackageTicket: false,
      isRedemptionTicket: false,
      listPackageTicket: [],
      maxQuantity: config.tickets.maxTicketsPerOrder,
      nbSeats: 1,
      price: t.priceInCents,
      quantity: t.quantity,
      tax: 0,
      voucherDisplayName: '',
      validatedTopVoucher: null,
      voucherCode: '',
      voucherGroupMaxQuantity: undefined,
      ticketExclusionStrategy: null,
      validatedVouchers: [],
      isThirdPartyMemberVoucher: false,
      longDescription: '',
      bookingFee: 0,
      bookingFeeTax: 0,
    }));

  return (
    <div className='season-pass-tickets'>
      <ContainedRow classNameCol='text-center'>
        <Heading className='mb-2' size={1}>
          {content.seasonPass.selectTicketsTitle}
        </Heading>
      </ContainedRow>
      <RichText
        className='mb-2'
        text={content.seasonPass.selectTicketsRichText}
      />

      <SelectedSeasonPassImage />

      <SelectedSeasonPassTitleAndLocation />

      <Row
        className='text-center w-100 tickets-container mt-3'
        sx={{
          borderBottom: 'bodySeparator',
        }}
      >
        <TicketSelector
          handleTicketButtonClick={handleTicketButtonClick}
          orderHasMaxTickets={false}
          ticketTypeModels={mappedSelectedSeasonPassTickets}
        />
      </Row>

      <div className='my-2 text-center w-100'>
        <ActionButton
          onClick={() => handleClick()}
          showIcon
          contained
          variant='primary'
          disabled={disableContinueButton}
          mt='mt-1'
          mx='mx-3'
        >
          {content.continueButtonText}
        </ActionButton>
      </div>
    </div>
  );
};

export default SeasonPassTickets;

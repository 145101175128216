import React from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { JOURNEY_TYPES } from '../../../../constants';
import { useBoostNavigate } from '../../../../hooks/useBoostNavigate';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectBookingData,
  selectContent,
  selectJourneyTypeConfig,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';

interface Props {
  handleContinueClick: () => void;
}

const LoggedInOptions: React.FC<Props> = ({ handleContinueClick }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, _setCookies, removeCookies] = useCookies();
  const boostNavigate = useBoostNavigate();
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const bookingData = useSelector(selectBookingData);
  const username = bookingData.loyaltyEmailAddress;

  const handleLogOutButtonClick = () => {
    if (bookingData.isUserValidated) {
      removeCookies('requestData', { path: '/' });
    }
    if (journeyTypeConfig.type === JOURNEY_TYPES.LOYALTY_UPGRADE) {
      dispatch(actionCreators.clearAvailableConcessions());
    }
    boostNavigate.navigateToStartJourneyUsingGlobalState();
  };

  return (
    <>
      <ContainedRow classNameCol='text-center'>
        <Heading className='mt-4' size={2}>
          {content.signIn.loggedInHeading}
        </Heading>
        <p className='my-2'>{username}</p>
        <RichText text={content.signIn.loggedInRichText} />

        <ActionButton
          onClick={() => handleContinueClick()}
          showIcon
          contained
          mx='mx-3'
          variant='primary'
        >
          {content.signIn.loggedInContinueButtonText}
        </ActionButton>
      </ContainedRow>

      <ContainedRow classNameRow='mt-3' classNameCol='text-center'>
        <div className='separator' />
      </ContainedRow>

      <ContainedRow classNameCol='text-center'>
        <Heading className='mt-5' size={2}>
          {content.signIn.loggedInLogOutHeading}
        </Heading>

        <ActionButton
          onClick={() => handleLogOutButtonClick()}
          showIcon
          contained
          mx='mx-3'
          variant='secondary'
        >
          {content.signIn.loggedInLogOutButtonText}
        </ActionButton>
      </ContainedRow>
    </>
  );
};

export default LoggedInOptions;

import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import CategoryLanding from './CategoryLanding';
import TabbedKiosk from './TabbedKiosk';

import { DeliveryPreference } from '../../../@types/enums';
import {
  AddConcessionRequestModel,
  GetConcessionsRequestModel,
  Concession,
} from '../../../@types/modelTypes';
import { JOURNEY_TYPES, PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectFaBConcessions,
  selectConcessionsAdded,
  selectConfig,
  selectContent,
  selectRequiresDeliveryOptions,
  selectSelectedFaBConcessions,
  selectSelectedDeliveryWindow,
  selectSelectedSeats,
  selectToken,
  selectJourneyTypeConfig,
  selectDeliveryOptionWhenNoChoices,
  selectContinueButtonText,
  selectIsInSeatDeliveryEnabled,
  selectIsDeliveryOptionBasedOnSeatingArea,
  selectHasSeatsWithSeatDeliveryAvailable,
  selectOrderToSeatOnly,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import DeliveryOptions from '../deliveryoptions/DeliveryOptions';
import Heading from '../heading/Heading';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

type Props = {
  isCategoryLanding?: boolean;
};
const FandBWrapper: React.FC<Props> = ({ isCategoryLanding }) => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();

  const availableFaBConcessions = useSelector(selectFaBConcessions);
  const bookingData = useSelector(selectBookingData);
  const concessionsAddedToPos: boolean = useSelector(selectConcessionsAdded);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const deliveryOptionWhenSingleOption = useSelector(
    selectDeliveryOptionWhenNoChoices
  );
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);
  const selectedSeats = useSelector(selectSelectedSeats);
  const requiresDeliveryOptions = useSelector(selectRequiresDeliveryOptions);
  const continueButtonText = useSelector(selectContinueButtonText);
  const isInSeatDeliveryEnabled = useSelector(selectIsInSeatDeliveryEnabled);
  const hasSeatsWithSeatDeliveryAvailable = useSelector(
    selectHasSeatsWithSeatDeliveryAvailable
  );
  const isDeliveryOptionBasedOnSeatingArea = useSelector(
    selectIsDeliveryOptionBasedOnSeatingArea
  );
  const isOrderToSeatOnly = useSelector(selectOrderToSeatOnly);
  const getConcessionsCalledRef = useRef(false);

  const [noConcessionReturn, setNoConcessionReturn] = useState<boolean>(false);
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);

  const hasConcessions = selectedConcessions?.list?.some(
    (x: Concession) => x.quantity > 0
  );

  const isDeliveryOptionsEnabled =
    (selectedSeats?.length > 0 || !isOrderToSeatOnly) &&
    hasConcessions &&
    requiresDeliveryOptions;

  const isGiftCardUpsellEnabled = config.enableGiftCardUpsellOnTicketingJourney;

  useEffect(() => {
    const getData = async () => {
      dispatch(actionCreators.setLoading(true));
      const data: GetConcessionsRequestModel = {
        dataToken: dataToken,
        bookingReference: bookingData.bookingReference,
      };
      const response = await backend.post(
        `api/Concessions?cinemaId=${bookingData.cinemaId}`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(
          actionCreators.setConcessions(response.content.listConcessionGrouping)
        );
        dispatch(
          actionCreators.setDeliveryWindows(response.content.deliveryWindows)
        );
        setNoConcessionReturn(false);
        if (response.content.deliveryOptionBasedOnSeatingArea === true) {
          dispatch(actionCreators.setDeliveryOptionBasedOnSeatingArea(true));
        }
      } else {
        setNoConcessionReturn(true);
      }
      dispatch(actionCreators.setLoading(false));
    };

    const shouldFetchConcessions = () => {
      if (!bookingData) return false;
      if (isInSeatDeliveryEnabled && !getConcessionsCalledRef.current) {
        getConcessionsCalledRef.current = true;
        return true;
      }
      if (!availableFaBConcessions) {
        return true;
      }
      return false;
    };

    if (shouldFetchConcessions()) {
      getData();
    }

    return () => {
      if (!isInSeatDeliveryEnabled) {
        getConcessionsCalledRef.current = false;
      }
    };
  }, [
    availableFaBConcessions,
    dispatch,
    bookingData,
    dataToken,
    isInSeatDeliveryEnabled,
  ]);

  const removeNoScroll = () => {
    document.documentElement.classList.remove('no-scroll');
    document.body.classList.remove('no-scroll');
  };

  const onActionButtonClick = () => {
    if (isDeliveryOptionBasedOnSeatingArea) {
      const deliveryPreference = hasSeatsWithSeatDeliveryAvailable
        ? DeliveryPreference.ORDER_TO_SEAT
        : DeliveryPreference.COLLECT_FROM_KIOSK;
      dispatch(actionCreators.setDeliveryPreference(deliveryPreference));
    }

    isDeliveryOptionsEnabled
      ? handleShowDeliveryOptions()
      : onContinueClick(deliveryOptionWhenSingleOption);
  };

  const getActionButtonText = () => {
    return isDeliveryOptionsEnabled
      ? content.concessionsOnlyJourney.continueToDeliveryOptionsText
      : continueButtonText;
  };

  const handleSendEntireBasketAndContinue = async (
    selectedDeliveryPreference: DeliveryPreference
  ) => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const data: AddConcessionRequestModel = {
        dataToken: dataToken,
        concessions: selectedConcessions ? selectedConcessions.list : [],
        journeyType: journeyTypeConfig.type,
        deliveryPreference: selectedDeliveryPreference,
        deliveryWindowInfo: selectedDeliveryWindow,
      };
      const response = await backend.post(
        `api/Concessions/Add/${bookingData.cinemaId}`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        dispatch(
          actionCreators.setGrandTotalWithDiscount(
            response.content.totalOrderAmount,
            response.content.totalDiscount
          )
        );
        if (config.enableCountDown) {
          dispatch(
            actionCreators.setCountDown(response.content.secondsToExpiration)
          );
        }
        continueWithJourney();
      } else {
        dispatch(actionCreators.clearConcessions(true));
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            response.content.peachCode
          )
        );
      }
    } else {
      continueWithJourney();
    }
    dispatch(actionCreators.setLoading(false));
  };

  const onContinueClick = (selectedDeliveryPreference: DeliveryPreference) => {
    if (config.useDynamicBasket) {
      dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
      continueWithJourney();
    } else {
      handleSendEntireBasketAndContinue(selectedDeliveryPreference);
    }
  };

  const handleShowDeliveryOptions = () => {
    document.documentElement.classList.add('no-scroll');
    document.body.classList.add('no-scroll');
    setShowDeliveryOptions(true);
  };

  const handleHideDeliveryOptions = () => {
    dispatch(actionCreators.setSelectedDeliveryWindow(null));
    removeNoScroll();
    setShowDeliveryOptions(false);
  };

  const continueWithJourney = () => {
    if (isGiftCardUpsellEnabled) {
      removeNoScroll();
      dispatch(actionCreators.setKioskSubStep('egc'));
    } else {
      const appendCinemaAndSessionIds =
        !journeyTypeConfig.isConcessionsOnlyJourney;

      boostNavigate.navigateToNextStep({
        appendCinemaAndSessionIdsFromUrl: appendCinemaAndSessionIds,
      });
    }
  };

  const continueDisabled =
    journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY &&
    !hasConcessions;

  return (
    <>
      {' '}
      {(content.kiosk.subTitle || content.kiosk.introductionRichText) && (
        <ContainedRow classNameCol='mt-3'>
          <Heading size={2} className='text-center'>
            {content.kiosk.subTitle}
          </Heading>
          <RichText text={content.kiosk.introductionRichText} />
        </ContainedRow>
      )}
      {isCategoryLanding ? <CategoryLanding /> : <TabbedKiosk />}
      {noConcessionReturn && (
        <ContainedRow>
          <div className='warning-container my-3 p-3'>
            <p>{content.kiosk.noConcessionsMessage}</p>
          </div>
        </ContainedRow>
      )}
      {(content.kiosk.additionalSubTitle ||
        content.kiosk.additionalRichText) && (
        <ContainedRow classNameCol='my-3'>
          <Heading size={2} className='text-center'>
            {content.kiosk.additionalSubTitle}
          </Heading>
          <RichText text={content.kiosk.additionalRichText} />
        </ContainedRow>
      )}
      <ActionButton
        onClick={onActionButtonClick}
        disabled={continueDisabled}
        stickyMobileDesktop={true}
        showIcon
        contained
        mx='mx-3'
        variant='primary'
        showCartSummaryButtonOnMobile
      >
        {getActionButtonText()}
      </ActionButton>
      {isDeliveryOptionsEnabled && (
        <div className={classnames(showDeliveryOptions && 'layout-mask')}>
          <div
            className={classnames(
              'delivery-option-container',
              showDeliveryOptions && 'show'
            )}
          >
            <DeliveryOptions
              continueButtonClick={onContinueClick}
              continueButtonText={continueButtonText}
              hideDeliveryOptions={handleHideDeliveryOptions}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FandBWrapper;

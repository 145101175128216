import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  AddConcessionRequestModel,
  Concession,
  ConcessionGrouping,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConcessionsAdded,
  selectConfig,
  selectContent,
  selectCustomer,
  selectSelectedFaBConcessions,
  selectTandCsAcceptedSignup,
  selectToken,
  selectJourneyTypeConfig,
  selectAllConcessions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CustomerDetailsSignup from '../../common/customerdetails/CustomerDetailsSignup';
import Heading from '../../common/heading/Heading';
import RichText from '../../common/richtext/RichText';
import SignUpSummaryTierItem from '../../common/signup/SignUpSummaryTierItem';

const SignupDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useValidateConcessionsJourney();
  const boostNavigate = useBoostNavigate();
  const availableConcessions = useSelector(selectAllConcessions);
  const concessionsAddedToPos = useSelector(selectConcessionsAdded);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const isTandCsAccepted = useSelector(selectTandCsAcceptedSignup);
  const concession: Concession | undefined = selectedConcessions.list.find(
    (c: Concession) => c.quantity === 1
  );

  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [signupFormValidated, setSignupFormValidated] =
    useState<boolean>(false);

  const hasConcessions = availableConcessions?.some(
    (x: ConcessionGrouping) => !!x.items.length
  );

  const hasSelection = selectedConcessions?.list.some((x) => x.quantity >= 1);

  useEffect(() => {
    setSignupFormValidated(!!(isTandCsAccepted && customer.isValid));
  }, [customer, isTandCsAccepted]);

  useEffect(() => {
    if (!hasSelection && showUserDetails) {
      setShowUserDetails(false);
    }
  }, [hasSelection, showUserDetails]);

  const addConcessionsToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const data: AddConcessionRequestModel = {
        dataToken: dataToken,
        concessions: selectedConcessions ? selectedConcessions.list : [],
        journeyType: journeyTypeConfig.type,
        deliveryWindowInfo: null,
        customer,
      };
      const response = await backend.post(
        'api/Member/AddMemberConcessions',
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        if (config.enableCountDown) {
          dispatch(
            actionCreators.setCountDown(response.content.secondsToExpiration)
          );
        }
        boostNavigate.navigateToNextStep();
      } else {
        const errorMessage =
          response.content.peachCode ===
            PEACH_CODES.loyaltyMemberAlreadyExists ||
          response.content.peachCode === PEACH_CODES.memberDetailsIncorrect
            ? content.error.loyaltyMemberAlreadyExistsRichText
            : content.error.concessionsCouldNotBeAddedRichText;
        dispatch(
          actionCreators.setError(errorMessage, response.content.peachCode)
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  if (!content?.loyalty) return null;

  return (
    <div className='loyalty px-2' data-testid='loyalty'>
      <Row className='text-center'>
        <Col xs={12} className='contained'>
          <Heading size={1}>{content.loyalty.signupSelectTierHeading}</Heading>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className='contained mt-3'>
          <Heading size={2} className='text-center'>
            {content.loyalty.signUpDetailsHeading}
          </Heading>
          <RichText
            text={content.loyalty.signupDetailsRichText}
            className='text-center'
          />
        </Col>
      </Row>

      <Row>
        {concession && (
          <Col md={12} className='contained loyalty-rows-container mt-4'>
            <SignUpSummaryTierItem
              {...concession}
              key={concession.id}
              defaultImage={
                concession?.image?.replace('https://', '') ??
                content.loyalty.defaultImage
              }
            />
          </Col>
        )}
      </Row>
      <Row className='text-start tiny'>
        <Col xs={12} className='contained'>
          <div className='mt-1 giftcards'>
            <Button
              onClick={() => navigate(-1)}
              variant='link'
              className='btn-edit b text-center text-uppercase'
            >
              {content.loyalty.paymentEditText}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className='mt-2 contained'>
          <Row>
            <Col xs={12} className='contained mt-3'>
              <Heading size={2} className='text-center'>
                {content.loyalty.signUpYourDetailsHeading}
              </Heading>
              <RichText text={content.loyalty.signupYourDetailsRichText} />
            </Col>
          </Row>
          <CustomerDetailsSignup />
        </Col>
      </Row>
      <Row>
        <Col md={12} className='mt-2'>
          <ActionButton
            onClick={addConcessionsToPos}
            disabled={!signupFormValidated}
            stickyMobileDesktop={false}
            showIcon
            contained
            mx='mx-3'
            variant='primary'
          >
            {content.loyalty.continueToPaymentButtonText}
          </ActionButton>
        </Col>
      </Row>
    </div>
  );
};

export default SignupDetails;

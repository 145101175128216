import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { JOURNEY_TYPES } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { useValidateJourney } from '../../../hooks/useValidateJourney';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import {
  selectContent,
  selectJourneyTypeConfig,
  selectKioskSubStep,
  selectStep,
} from '../../../store/Selectors';
import Heading from '../../common/heading/Heading';
import EGiftCardWrapper from '../../common/kiosk/EGiftCardWrapper';
import FandBWrapper from '../../common/kiosk/FandBWrapper';
import ContainedRow from '../../common/layout/ContainedRow';
import ShowtimeHeroConcessions from '../../common/showtimehero/ShowtimeHeroConcessions';
import TotalWrapper from '../../common/totalwrapper/TotalWrapper';

const Kiosk: React.FC = () => {
  const { isLargeScreenWidth } = useScreenWidth();

  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);
  const kioskSubStep = useSelector(selectKioskSubStep);

  useValidateJourney();

  const showShowtimeHero =
    journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY;

  if (!content) return null;

  return (
    <div className='kiosk' data-testid='kiosk'>
      <ContainedRow classNameRow='text-center'>
        <Heading size={1}>
          {getStepNumberForDisplay(step)}
          {kioskSubStep === 'egc'
            ? content.kiosk.giftCardUpsellTitle
            : content.kiosk.title}
        </Heading>
      </ContainedRow>

      {showShowtimeHero && (
        <ContainedRow>
          <ShowtimeHeroConcessions />
        </ContainedRow>
      )}

      {isLargeScreenWidth && <TotalWrapper />}
      {kioskSubStep === 'fab-landing' && (
        <FandBWrapper isCategoryLanding={true} />
      )}

      {kioskSubStep === 'fab' && <FandBWrapper />}
      {kioskSubStep === 'egc' && (
        <Row className='mt-4 available-concessions-tabs giftcards'>
          <Col md={{ span: 10, offset: 1 }}>
            <EGiftCardWrapper />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Kiosk;

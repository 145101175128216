/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, NavLink, Flex, Image } from 'theme-ui';

import { KioskCategoryLandingPageSetting } from '../../../@types/enums';
import { ConcessionGrouping } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectFaBConcessions,
} from '../../../store/Selectors';
import { ReactComponent as CategoryImagePlaceholder } from '../../../svgs/categoryImagePlaceholder.svg';
import { ReactComponent as IconArrowSvg } from '../../../svgs/iconArrow.svg';

const CategoryLanding: React.FC = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  const tabContainer = useRef<null | HTMLDivElement>(null);
  const concessions = useSelector(selectFaBConcessions);

  const selectCategory = (i: number) => {
    if (tabContainer.current) {
      tabContainer.current.scrollIntoView({ block: 'start' });
    }
    dispatch(actionCreators.setKioskActiveCategory(i));
    dispatch(actionCreators.setKioskSubStep('fab'));
  };
  const getCategoryImage = (type: number, title: string) => {
    const categoryImage =
      content.kiosk?.foodAndBeverageCategoryImages.find(
        (x) => x.categoryTitle === title
      )?.categoryImage || config.kiosk?.defaultCategoryImage;
    const size =
      type === KioskCategoryLandingPageSetting.TextAndImagesList
        ? ['55px', '55px', '100px']
        : '216px';
    if (categoryImage) {
      return (
        <Image
          src={categoryImage}
          alt={title}
          sx={{
            height: 'min-content',
            maxHeight: size,
            width: '100%',
            maxWidth: size,
            objectFit: 'scale-down',
            objectPosition: '50%',
            border: 'boxSeparatorLight',
            borderRadius: '5px',
            '&:hover': {
              borderColor: 'primary',
            },
          }}
          py={
            type === KioskCategoryLandingPageSetting.TextAndImagesList ? 1 : 3
          }
          px={
            type === KioskCategoryLandingPageSetting.TextAndImagesList ? 1 : 4
          }
          mb={
            type === KioskCategoryLandingPageSetting.TextAndImagesList ? 0 : 3
          }
          mr={
            type === KioskCategoryLandingPageSetting.TextAndImagesList ? 4 : 0
          }
        />
      );
    } else {
      return (
        <CategoryImagePlaceholder
          sx={{
            marginBottom:
              type === KioskCategoryLandingPageSetting.TextAndImagesList
                ? 0
                : 3,
            marginRight:
              type === KioskCategoryLandingPageSetting.TextAndImagesList
                ? 4
                : 0,
            paddingY:
              type === KioskCategoryLandingPageSetting.TextAndImagesList
                ? 1
                : 3,
            paddingX:
              type === KioskCategoryLandingPageSetting.TextAndImagesList
                ? 1
                : 4,
            height: 'min-content',
            maxHeight: size,
            width: '100%',
            maxWidth: size,
            objectFit: 'scale-down',
            objectPosition: '50%',
            border: 'boxSeparatorLight',
            borderRadius: '5px',
            '&:hover': {
              borderColor: 'primary',
            },
          }}
        />
      );
    }
  };
  return (
    <Grid gap={4} mt={2}>
      <Box
        sx={{
          backgroundColor: 'websiteBackground',
        }}
        px={0}
        mt={5}
      >
        {config.kiosk?.enableKioskCategoryLandingPage ===
          KioskCategoryLandingPageSetting.TextOnly && (
          <Flex
            px={[0, 0, 2]}
            variant='boxes.contained'
            sx={{ flexDirection: 'column' }}
          >
            {concessions?.map((x: ConcessionGrouping, i: number) => (
              <NavLink
                key={`category-tab-button-${i}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderTop: 'boxSeparator',
                  fontWeight: 'normal',
                  '&:hover': {
                    textDecoration: 'none',
                    '& svg': {
                      fill: 'primary',
                    },
                  },
                  '&:last-of-type': {
                    borderBottom: 'boxSeparator',
                  },
                  '& svg': {
                    fill: 'primaryButtonColor',
                  },
                }}
                onClick={() => selectCategory(i)}
                onKeyDown={(e) =>
                  e.key === 'Enter' ? selectCategory(i) : null
                }
                py={4}
                px={4}
                tabIndex={0}
              >
                {x.title}
                <IconArrowSvg className='icon-arrow' />
              </NavLink>
            ))}
          </Flex>
        )}
        {config.kiosk?.enableKioskCategoryLandingPage ===
          KioskCategoryLandingPageSetting.TextAndImagesList && (
          <Flex
            px={[0, 0, 2]}
            variant='boxes.contained'
            sx={{ flexDirection: 'column' }}
          >
            {concessions?.map((x: ConcessionGrouping, i: number) => (
              <NavLink
                key={`category-tab-button-${i}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderTop: 'boxSeparator',
                  fontWeight: 'normal',
                  '&:hover': {
                    color: 'mostReadableOnWebsiteBackground',
                    textDecoration: 'none',
                    '& svg': {
                      borderColor: 'primary',
                    },
                    '& img': {
                      borderColor: 'primary',
                    },
                  },
                  '&:last-of-type': {
                    borderBottom: 'boxSeparator',
                  },
                  '& svg': {
                    fill: 'primaryButtonColor',
                  },
                }}
                onClick={() => selectCategory(i)}
                onKeyDown={(e) =>
                  e.key === 'Enter' ? selectCategory(i) : null
                }
                py={4}
                px={4}
                tabIndex={0}
              >
                <div>
                  {getCategoryImage(
                    config.kiosk?.enableKioskCategoryLandingPage,
                    x.title
                  )}
                  {x.title}
                </div>
                <IconArrowSvg className='icon-arrow' />
              </NavLink>
            ))}
          </Flex>
        )}
        {config.kiosk?.enableKioskCategoryLandingPage ===
          KioskCategoryLandingPageSetting.TextAndImagesGrid && (
          <Grid
            gap={6}
            columns={[2]}
            px={[0, 0, 2]}
            variant='boxes.contained'
            sx={{}}
          >
            {concessions?.map((x: ConcessionGrouping, i: number) => (
              <NavLink
                key={`category-tab-button-${i}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  fontWeight: 'normal',
                  textAlign: 'center',
                  '&:hover': {
                    textDecoration: 'none',
                    color: 'mostReadableOnWebsiteBackground',
                  },
                  '&:focus': {
                    textDecoration: 'none',
                  },
                }}
                onClick={() => selectCategory(i)}
                onKeyDown={(e) =>
                  e.key === 'Enter' ? selectCategory(i) : null
                }
                tabIndex={0}
                mb={6}
              >
                {getCategoryImage(
                  config.kiosk?.enableKioskCategoryLandingPage,
                  x.title
                )}
                {x.title}
              </NavLink>
            ))}
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default CategoryLanding;
